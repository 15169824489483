<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isEditSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Editar curso
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="submit()"
          @reset.prevent="resetForm"
        >
          <!-- Event -->
          <validation-provider
            v-slot="{ errors }"
            name="title"
            rules="required"
          >
            <b-form-group
              label="Título"
              label-for="title"
            >
              <b-form-input
                id="title"
                v-model="form.title"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Speaker -->
          <validation-provider
            v-slot="{ errors }"
            name="description"
            rules="required"
          >
            <b-form-group
              label="Descrição"
              label-for="description"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-textarea
                id="textarea-state"
                v-model="form.description"
                :state="errors.length > 0 ? false : null"
                placeholder="Descrição..."
                rows="3"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="about"
            rules="required"
          >
            <b-form-group
              label="Descrição detalhada do curso"
              label-for="about"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-textarea
                id="textarea-about"
                v-model="form.about"
                :state="errors.length > 0 ? false : null"
                placeholder="Descrição..."
                rows="8"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="thumb"
          >
            <b-form-group
              label="Capa"
              label-for="thumb"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-file
                id="thumb"
                v-model="form.thumb"
                placeholder="Selecione o arquivo..."
                drop-placeholder="Arraste seu arquivo para aqui..."
                multiple
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            name="amount"
            rules="required"
          >
            <b-form-group
              label="Valor"
              label-for="amount"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="amount"
                v-model="form.amount"
                type="number"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            name="level"
            rules="required"
          >
            <b-form-group
              label="Nível"
              label-for="level"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="level"
                v-model="form.level"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            name="language"
            rules="required"
          >
            <b-form-group
              label="Idioma"
              label-for="language"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="language"
                v-model="form.language"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            name="caption"
            rules="required"
          >
            <b-form-group
              label="Legenda"
              label-for="caption"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="caption"
                v-model="form.caption"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>

import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, VBTooltip
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import {
  required
} from 'vee-validate/dist/rules'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CoursesServices from './coursesServices'

extend('required', {
  ...required,
  message: 'Esse campo é obrigatório.',
})

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    // vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    isEditSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        id: null,
        title: null,
        description: null,
        about: null,
        amount: null,
        thumb: null,
        language: null,
        caption: null,
        level: null,
      },
    }
  },
  computed: {},
  watch: {},
  mounted() {
    console.log(this.data)
    const amount = this.data.amount.split(' ') // remove R$

    this.form.id = this.data.id
    this.form.title = this.data.title
    this.form.description = this.data.description
    this.form.about = this.data.about
    // eslint-disable-next-line prefer-destructuring
    // eslint-disable-next-line radix
    this.form.amount = parseInt(amount[1])
    this.form.level = this.data.level
    this.form.caption = this.data.caption
    this.form.language = this.data.language
  },
  created() {},
  methods: {
    submit() {
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          try {
            const image = document.querySelector('#thumb')
            const formData = new FormData()
            formData.append('id', this.form.id)
            formData.append('title', this.form.title)
            formData.append('description', this.form.description)
            formData.append('about', this.form.about)
            formData.append('amount', this.form.amount)
            formData.append('level', this.form.level)
            formData.append('caption', this.form.caption)
            formData.append('language', this.form.language)
            formData.append('thumb', image.files[0])
            await CoursesServices().edit(formData).then(api => {
              if (api.data.status === 400) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    icon: 'InfoIcon',
                    title: api.data.message,
                    variant: 'danger',
                  }
                })
              } else if (api.data.status === 200) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: api.data.message,
                    icon: 'InfoIcon',
                    variant: 'success',
                  }
                })
                this.$emit('close')
                this.$emit('refresh')
              }
            }).catch(err => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: 'InfoIcon',
                  title: err.message,
                  variant: 'danger',
                }
              })
            })
          } catch (err) {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: 'InfoIcon',
                title: err.message,
                variant: 'danger',
              }
            })
          }
        }
      })
    },
    resetForm() {
      this.form = {
        amount: null,
        title: null,
        description: null,
        language: null,
        caption: null,
        level: null,
        about: null,
      }
      this.$emit('close')
    },
    sortByName(a, b) {
      if (a.text < b.text) {
        return -1
      }
      if (a.text > b.text) {
        return 1
      }
      return 0
    },
    sortByDate(a, b) {
      if (a.date < b.date) {
        return -1
      }
      if (a.date > b.date) {
        return 1
      }
      return 0
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
